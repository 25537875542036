import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Page, Section, Grid, BigGridBlock, Carousel3d, PostPreview } from 'src/components';
import paths from 'src/constants/paths';

const QUERY = graphql`
  query CaseStudiesQuery {
    allGraphCmsCaseStudy {
      nodes {
        id
        title
        date
        body
        color
        slug
        location
        teaser
        image {
          url
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, width: 4000, aspectRatio: 1.33)
        }
        people {
          id
          firstName
          lastName
          position
          location
        }
      }
    }
  }
`;

/**
 * Creatour Home
 */
const CreatourPage = () => {
  const data = useStaticQuery(QUERY);
  const posts = data?.allGraphCmsCaseStudy?.nodes;
  return (
    <Page
      dark
      darkColor={'#000000'}
      path={paths.CREATOR}
      title="Creatour"
      navBorder={false}
      image="/img/og/creatour.png"
    >
      <Section dark large nav top center wide noFade>
        <b1 style={{ marginBottom: 24, opacity: 0.5 }}>Creatour</b1>

        <h1
          className="wide"
          style={{
            // fontSize: 'min(6vw, 6vh)',
            // lineHeight: 1,
            maxWidth: 'var(--width-read)',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        >
          Meet the modern workforce that enjoys modern benefits
          <br />
        </h1>

        <Carousel3d
          items={posts?.map((post) => ({
            title:
              post.people?.[0]?.firstName +
              (post.people?.[1]?.firstName ? ' & ' + post.people?.[1]?.firstName : ''),
            subtitle: post?.people?.[0]?.position,
            image: post?.image?.gatsbyImageData || post?.themeImage?.gatsbyImageData,
            path: `${paths.CREATOR}/${post.slug}`,
            color: post.color,
          }))}
        />
        <article className="article" style={{ textAlign: 'center', margin: '0 auto' }}>
          <div>
            <h2 className="r o85">
              Creatour is a cross-country tour, meeting customers, creators, makers, and
              independents, bringing together the unique voices of the modern American workforce.
            </h2>
          </div>
          <div>
            <h2 className="r o85">
              We celebrate the wins, highlight the challenges, and recognize the impact that
              freelance creators have.
            </h2>
          </div>
        </article>
      </Section>

      <Section>
        {posts?.map((post) => (
          <PostPreview
            eyebrow={post.location}
            key={post.id}
            color={post.color}
            link={'/creatour/' + post.slug}
            title={
              post?.people?.length > 1
                ? `${post?.people?.[0]?.firstName} & ${post?.people?.[1]?.firstName} `
                : `${post?.people?.[0]?.firstName} ${post?.people?.[0]?.lastName} `
            }
            subtitle={post.people?.[0]?.position}
            teaser={post.teaser}
            image={post.image?.gatsbyImageData}
          />
        ))}
      </Section>

      <Section raw narrow>
        <Grid num={2}>
          <BigGridBlock
            glyph
            small
            img="/iconic/announcement.svg"
            title="About Creatour"
            subtitle="Read the announcement"
            color="var(--g-cerise-light)"
            linkTo="/post/catch-goes-on-tour-creatour"
          />
          <BigGridBlock
            glyph
            small
            img="/iconic/users.svg"
            title="More Stories"
            subtitle="The Catch Community"
            color="var(--g-cerise-light)"
            linkTo="/community"
          />
        </Grid>
      </Section>
      <Section small />
    </Page>
  );
};

export default CreatourPage;
